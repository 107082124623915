* {
  margin: 0px;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: radial-gradient(circle, #096c0a, #011704);
  margin: 0px;
  max-width: 800px;
  font-family: '나눔스퀘어OTF_ac Bold';
  background-color: black;
  margin-left: auto;
  margin-right: auto;
}
