/* 전체 레이아웃 스타일 */
.pageContainer {
    display: flex;
    width: 960px;
    height: 860px;
  }

    /* about page */
    .aboutPage {
      width: 100%;  /* 화면 전체 너비 */
  max-width: 100%; /* 최대 너비를 100%로 */
  height: 1000px;
  background-color: #111; /* 짙은 회색 */
  padding: 20px;
  box-sizing: border-box;
  
  border-left: 8px solid #39FF14; /* 왼쪽에 녹색 선 추가 */
  color: #aaa;
    }
    .aboutPage > * {
      padding-right: 10px; /* 각 요소의 아래쪽에 20px의 패딩 추가 */
    }
  
  
  /* 채팅창 스타일 */
  .chatBox {
    width: 320px;
    height: 840px;
    background-color: #111; /* 짙은 회색 */
    padding: 20px;
    box-sizing: border-box;
    border-left: 4px solid #000;
    color:#aaa;
  }
  
  /* 캔버스와 상단 박스 컨테이너 */
  .canvasContainer {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 1100px;
  }
  
  /* 캔버스 상단 박스 스타일 */
  .topBox {
    width: auto;
    height: 300px;
    background-color: #111; /* 짙은 회색 */
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 4px solid #000;
    color:#aaa;
    
  }
  .topBox > * {
    padding-right: 10px; /* 각 요소의 아래쪽에 20px의 패딩 추가 */
  }
  
  /* 캔버스 스타일 */
  .canvas {
    width: 800px;
    height: 800px;    
  }
  
  .pageBottomADContainer {
    display: flex;
    width: 960px;
    height: auto;
    background-color: #111; /* 짙은 회색 */
  }